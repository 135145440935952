import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useColorMode } from "@chakra-ui/react";

import Home from "./pages/Home";
import Works from "./pages/Works";
import Biography from "./pages/Biography";
import About from "./pages/About";
import Contributions from "./pages/Contributions";

import NavigationBar from "./components/NavigationBar";

const App = () => {
	// const { colorMode, toggleColorMode } = useColorMode();
	// if (colorMode !== `light`) toggleColorMode();

	return (
		<BrowserRouter>
			<NavigationBar />
			<Routes>
				<Route path={`/`} element={<Home />} />
				<Route path={`/works`} element={<Works />} />
                <Route path={`/biography`} element={<Biography />} />
				<Route path={`/about`} element={<About />} />
				<Route path={`/contributions`} element={<Contributions />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;