import { Box, Container, Divider, Flex, keyframes, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { useRef } from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import { Parallax as SpringParallax } from "@react-spring/parallax";

import HomeInfoSection from "../components/HomeInfoSection";
import HomeData from "../assets/data/Home";
import TimeLineElement from "../components/TimeLineElement";

import ImageModal from "../components/ImageModal";

import { Typewriter } from "react-simple-typewriter";
import { CheckIcon, InfoIcon } from "@chakra-ui/icons";

const Home = () => {
	const scrollDown = keyframes`
		0% {transform: translate(-50%, 0px); opacity: 1;}
		80% {transform: translate(-50%, 25px); opacity: 0;}
		100% {transform: translate(-50%, 25px); opacity: 0;}
	`;
	const scrollDownAnimation = `${scrollDown} infinite 1.5s linear`;
	const scrollIconRef = useRef();

	const heroInfoIconGlow = keyframes`
		from { box-shadow: 0 0 10px -10px rgba(255, 255, 255, 1); }
		to { box-shadow: 0 0 15px 2.5px rgba(255, 255, 255, 1); }
	`;
	const heroInfoIconGlowAnimation = `${heroInfoIconGlow} infinite 1s alternate`;
	const heroInfoIconRef = useRef();
	const { isOpen: heroInfoIconIsOpen, onOpen: heroInfoIconOnOpen, onClose: heroInfoIconOnClose } = useDisclosure();

	const heroRef = useRef();
	const homeInfoSectionsRef = useRef();
	
	return (
		<ParallaxProvider>
		<Flex position={`relative`} w={`100%`} direction={`column`} backgroundColor={`white`} transition={`background 0.5s ease-in`}>
				<Parallax
					style={{display: `flex`, width: `100%`, height: `250vh`, marginBottom: `40px`, flexDirection: `row`, justifyContent: `center`}}
					onProgressChange={(progress) => {
						let p1 = 0.30, p2 = 0.50;
						if (progress < p1) {
							heroRef.current.children[0].children[0].style.marginTop = `0px`;
							heroRef.current.children[0].children[1].style.fontSize = `max(90px, 7.5vw)`;
						}
						else if (progress < p2) {
							heroRef.current.style.transition = `all 0.5s ease-in`;
							heroRef.current.children[0].style.transition = `background-color 0.5s ease-in`;
							heroRef.current.children[0].style.backgroundColor = `rgba(255,255,255,1)`;
							heroRef.current.children[0].children[1].style.transition = `none`;
							heroRef.current.children[1].style.transition = `opacity 0.5s ease-in 0s`;
							heroInfoIconRef.current.style.transition = `opacity 0.5s ease-in 0s`;
							
							heroRef.current.style.top = `0px`;
							heroRef.current.style.width = `100%`;
							heroRef.current.style.height = `100vh`;
							heroRef.current.style.borderRadius = `0px`;
							heroRef.current.style.boxShadow = ``;
							heroRef.current.children[0].children[0].style.marginTop = `-${1400*(progress-p1)}px`;
							heroRef.current.children[0].children[1].style.fontSize = `calc(max(90px, 7.5vw) + ${2000*(progress-p1)}px)`;
							heroRef.current.children[1].style.opacity = 0;
							scrollIconRef.current.style.opacity = 0;
							heroInfoIconRef.current.style.opacity = 0;
						}
						else {
							heroRef.current.style.transition = `all 0.5s ease-in 0.75s`;
							heroRef.current.children[0].style.transition = `background-color 0.5s ease-in 0.5s`;
							heroRef.current.children[0].children[1].style.transition = `font-size ease-in 1s`;
							heroRef.current.children[1].style.transition = `opacity 0.5s ease-in 1s`;
							heroInfoIconRef.current.style.transition = `opacity 0.5s ease-in 1s`;
							
							heroRef.current.style.top = `2.5vh`;
							heroRef.current.style.width = `94%`;
							heroRef.current.style.height = `95vh`;
							heroRef.current.style.borderRadius = `40px`;
							heroRef.current.style.boxShadow = `0px 0px 25px 0px rgba(0,0,0,0.75)`;
							heroRef.current.children[0].style.backgroundColor = `rgba(255,255,255,0)`;
							heroRef.current.children[0].children[1].style.fontSize = `5000px`;
							heroRef.current.children[1].style.opacity = 1;
							heroInfoIconRef.current.style.opacity = 1;
							scrollIconRef.current.style.opacity = 0;
						}
					}}
				>
					<Flex
						ref={heroRef}
						position={`sticky`}
						top={`0px`}
						w={`100%`}
						h={`100vh`}
						direction={`row`}
						justifyContent={`center`}
						alignItems={`center`}
						backgroundImage={HomeData.homeHero.image}
						backgroundSize={`cover`}
						backgroundPosition={`right`}
						overflow={`hidden`}
						transition={`all 0.5s ease-in`}
					>
						<Flex
							position={`absolute`}
							w={`100%`}
							h={`100%`}
							backgroundColor={`rgba(255,255,255,1)`}
							color={`black`}
							direction={`column`}
							justifyContent={`center`}
							alignItems={`center`}
							fontSize={`max(90px, 7.5vw)`}
							userSelect={`none`}
							mixBlendMode={`screen`}
							transition={`background-color 0.5s ease-in 0.5s`}
						>
							<Text textAlign={`center`} mt={`-800px`} lineHeight={`120%`}>
								<Typewriter words={[`Sheikh Muhammad Mustafa`, `شيخ محمد مصطفى`]} loop={2} cursor cursorStyle={``} typeSpeed={50} deleteSpeed={40} delaySpeed={5000}/>
							</Text>
							<Text whiteSpace={`nowrap`}>
								<Typewriter words={[`Al-Azami`, `الأعظمي`]} loop={2} cursor cursorStyle={``} typeSpeed={150} deleteSpeed={100} delaySpeed={5000}/>
							</Text>
						</Flex>
						{/* About */}
						<Flex 
							position={`absolute`}
							w={`100%`}
							h={`100%`}
							backgroundImage={HomeData.homeHero.image}
							backgroundSize={`cover`}
							backgroundPosition={`right`}
							direction={`column`}
							justifyContent={`flex-end`}
							alignItems={`flex-start`}
							opacity={0}
							userSelect={`none`}
							transition={`opacity 0.5s ease-in 1s`}
						>
							<Flex direction={`column`} w={`100%`} justifyContent={`end`} background={`linear-gradient(to top, rgba(0, 0, 0, 0.95), transparent)`} p={`30px`} textShadow={`0 2px 3px rgba(0, 0, 0, 0.9)`}>
								<Text fontSize={{base: `6xl`, lg: `7xl`}} color={`white`}>{HomeData.homeHero.title}</Text>
								<Divider mb={`20px`} />
								<Text fontSize={{base: `2xl`, lg: `3xl`}} color={`white`}>{HomeData.homeHero.description}</Text>
							</Flex>
						</Flex>
						<InfoIcon ref={heroInfoIconRef} position={`absolute`} right={`4vh`} top={`4vh`} boxSize={`10`} color={`white`} rounded={`full`} cursor={`pointer`} opacity={0} transition={`opacity 0.5s ease-in`} animation={heroInfoIconGlowAnimation} onClick={heroInfoIconOnOpen}/>
						<Modal isOpen={heroInfoIconIsOpen} onClose={heroInfoIconOnClose}>
							<ModalOverlay />
							<ModalContent>
								<ModalHeader>{HomeData.homeHero.imageTitle}</ModalHeader>
								<ModalCloseButton />
								<Divider />
								<ModalBody>
									{HomeData.homeHero.imageInfo}
								</ModalBody>
							</ModalContent>
						</Modal>
					</Flex>
				</Parallax>

				<Parallax
					style={{position: `relative`, width: `100%`, height: `300vw`, boxShadow: `rgba(0, 0, 0, 0.25) 0px 3px 8px`}}
					onProgressChange={(progress) => {
                        let startLimit = 0.25; // 0.2 for exact
                        let endLimit = 0.8;
                        if (progress < startLimit) {
                            homeInfoSectionsRef.current.container.current.scrollLeft = 0;
                        }
                        else if (progress < endLimit) {
                            homeInfoSectionsRef.current.container.current.scrollLeft = ((progress-startLimit)*(1/(endLimit - startLimit))*3)*1500;
                        }
                        else {
                            homeInfoSectionsRef.current.container.current.scrollLeft = 9999;
                        }
                    }}
				>
					<SpringParallax ref={homeInfoSectionsRef} pages={3} horizontal style={{position: `sticky`, top: `0px`, width: `100%`, height: `100vh`, overflow: `hidden`}}>
						{
							HomeData.homeInfo && HomeData.homeInfo.map((section, i) => {
								return (
									<HomeInfoSection key={i} data={section} page={i}/>
								);
							})
						}
					</SpringParallax>
				</Parallax>

				<Parallax>
					<Flex width={`100%`} height={`100%`} backgroundColor={`rgba(225, 225, 225, 0.5)`} backgroundImage={`radial-gradient(black 1px, transparent 0)`} backgroundSize={`40px 40px`} boxShadow={`inset 0px 0px 20px 0px rgba(0, 0, 0, 0.25)`}>
					<Flex w={`100%`} h={`100%`} direction={`column`} fontSize={`lg`} fontWeight={`semibold`}>
						<Text w={`100%`} textAlign={`center`} fontSize={`7xl`} fontWeight={`semibold`} margin={`75px 0px 75px 0px`}>Works Authored by the Shiekh</Text>
						<TimeLineElement date={`1968`}>
							<Flex width={`400px`} height={`200px`} padding={`20px`} background={`white`} boxShadow={`md`} rounded={`2xl`} direction={`column`}>
								<Text>Studies in early Hadith Literature.</Text>
								<Text>PHD thesis, translated into: Turkish, Indonesian, Urdu, Arabic</Text>
								<Text marginTop={`10px`} fontSize={`md`} fontWeight={`normal`}>I purposely haven't decorated these timeline elements...</Text>
							</Flex>
						</TimeLineElement>

						<TimeLineElement date={`1976`} right>
							<Flex width={`400px`} height={`200px`} padding={`20px`} background={`white`} boxShadow={`md`} rounded={`2xl`} direction={`column`}>
								<Text dir={`rtl`}>دراسات في الحديث النبوي و تاريخ تدوينه</Text>
							</Flex>
						</TimeLineElement>

						<TimeLineElement date={`1977`}>
							<Flex width={`400px`} height={`200px`} padding={`20px`} background={`white`} boxShadow={`md`} rounded={`2xl`} direction={`column`}>
								<Text>Hadith Methodology & Literature.</Text>
								<Text>Translated into: English, Tamil, Malasian, Seri Lanka, Turkish</Text>
							</Flex>
						</TimeLineElement>

						<TimeLineElement date={`1970s est.`} right>
							<Flex width={`400px`} height={`200px`} padding={`20px`} background={`white`} boxShadow={`md`} rounded={`2xl`} direction={`column`}>
								<Text dir={`rtl`}>كتاب النبي صلى الله عليه وسلم</Text>
							</Flex>
						</TimeLineElement>

						<TimeLineElement date={`1970s est.`} right>
							<Flex width={`400px`} height={`200px`} padding={`20px`} background={`white`} boxShadow={`md`} rounded={`2xl`} direction={`column`}>
								<Text dir={`rtl`}>منهج النقد عند المحدثين مع كتاب التميز للإمام مسلم</Text>
							</Flex>
						</TimeLineElement>

						<TimeLineElement date={`1980s est.`} right>
							<Flex width={`400px`} height={`200px`} padding={`20px`} background={`white`} boxShadow={`md`} rounded={`2xl`} direction={`column`}>
								<Text dir={`rtl`}>المحدثون من اليمامة</Text>
							</Flex>
						</TimeLineElement>

						<TimeLineElement date={`1980s est.`} right>
							<Flex width={`400px`} height={`200px`} padding={`20px`} background={`white`} boxShadow={`md`} rounded={`2xl`} direction={`column`}>
								<Text dir={`rtl`}>رواة الموطأ</Text>
							</Flex>
						</TimeLineElement>

						<TimeLineElement date={`1985`}>
							<Flex width={`400px`} height={`200px`} padding={`20px`} background={`white`} boxShadow={`md`} rounded={`2xl`} direction={`column`}>
								<Text>On Schacht's Origin of Muhammadan Jurisprudence.</Text>
								<Text>Translated into: Turkish, Urdu, Arabic</Text>
							</Flex>
						</TimeLineElement>

						<TimeLineElement date={`1990s est.`} right>
							<Flex width={`400px`} height={`200px`} padding={`20px`} background={`white`} boxShadow={`md`} rounded={`2xl`} direction={`column`}>
								<Text dir={`rtl`}>(دراسة نقدية) أصول الفقه الإسلامي للمستشرق شاخت</Text>
							</Flex>
						</TimeLineElement>

						<TimeLineElement date={`1990s est.`} right>
							<Flex width={`400px`} height={`200px`} padding={`20px`} background={`white`} boxShadow={`md`} rounded={`2xl`} direction={`column`}>
								<Text dir={`rtl`}>تاريخ النص القرآني</Text>
							</Flex>
						</TimeLineElement>

						<TimeLineElement date={`2000s est.`} right>
							<Flex width={`400px`} height={`200px`} padding={`20px`} background={`white`} boxShadow={`md`} rounded={`2xl`} direction={`column`}>
								<Text dir={`rtl`}>النص القرآني الخالد</Text>
							</Flex>
						</TimeLineElement>

						<TimeLineElement date={`2003`}>
							<Flex width={`400px`} height={`200px`} padding={`20px`} background={`white`} boxShadow={`md`} rounded={`2xl`} direction={`column`}>
								<Text>The history of the Quranic Text from Revelation to Compilation.</Text>
								<Text>Translated into: Turkish...</Text>
							</Flex>
						</TimeLineElement>

						<TimeLineElement date={`2016`}>
							<Flex width={`400px`} height={`200px`} padding={`20px`} background={`white`} boxShadow={`md`} rounded={`2xl`} direction={`column`}>
								<Text>The Scribes of the Prophet.</Text>
								<Text>Translated into: ...</Text>
							</Flex>
						</TimeLineElement>

						<TimeLineElement date={`2017`}>
							<Flex width={`400px`} height={`200px`} padding={`20px`} background={`white`} boxShadow={`md`} rounded={`2xl`} direction={`column`}>
								<Text>Ageless Quran Timeless Text.</Text>
								<Text>Translated into: ...</Text>
							</Flex>
						</TimeLineElement>
					</Flex>
					</Flex>
				</Parallax>

				<Parallax>
					<Flex w={`100%`} h={`600px`} backgroundColor={`white`} justifyContent={`center`} alignItems={`center`} direction={`column`}>
						<Text fontSize={`3xl`}>In the making...</Text>
					</Flex>
				</Parallax>

				<Parallax>
				<Box
					bg={useColorModeValue('gray.50', 'gray.900')}
					color={useColorModeValue('gray.700', 'gray.200')}
					boxShadow={`dark-lg`}
				>
					<Container
						as={Stack}
						maxW={'6xl'}
						py={4}
						spacing={4}
						justify={'center'}
						align={'center'}>
						<Text>Logo Goes Here</Text>
						<Stack direction={'row'} spacing={6}>
						<Link href={'#'}>Home</Link>
						<Link href={'#'}>Work</Link>
						<Link href={'#'}>Biography</Link>
						<Link href={'#'}>Contact</Link>
						</Stack>
					</Container>

					<Box
						borderTopWidth={1}
						borderStyle={'solid'}
						borderColor={useColorModeValue('gray.200', 'gray.700')}>
						<Container
						as={Stack}
						maxW={'6xl'}
						py={4}
						direction={{ base: 'column', md: 'row' }}
						spacing={4}
						justify={{ base: 'center', md: 'space-between' }}
						align={{ base: 'center', md: 'center' }}>
						<Text>© 2023 Azami Foundation. All rights reserved</Text>
						</Container>
					</Box>
					</Box>
				</Parallax>
				<Flex ref={scrollIconRef} position={`fixed`} direction={`column`} alignItems={`center`} left={`50%`} transform={{base: `translateX(-50%) scale(0.75)`, md: `translateX(-50%) scale(0.8)`}} bottom={`10px`} gap={`5px`} opacity={0.6} transition={`opacity 1s ease-in`}>
					<Flex w={`40px`} h={`60px`} border={`3px solid black`} rounded={`3xl`}>
						<Flex position={`absolute`} left={`50%`} top={`10px`} transform={`translateX(-50%)`} w={`10px`} h={`10px`} rounded={`3xl`} backgroundColor={`black`} animation={scrollDownAnimation}/>
					</Flex>
					<Text fontWeight={`semibold`} fontSize={`14px`} color={`black`}>Scroll Down</Text>
				</Flex>
		</Flex>
		</ParallaxProvider>
	);
}

export default Home;