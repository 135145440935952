import { Button, Flex, Image, Text, useDisclosure, useBreakpointValue, Modal, ModalOverlay, ModalContent, ModalCloseButton, Divider } from "@chakra-ui/react";
import { useState } from "react";

const Book = ({book, visibility}) => {
	const frontRotateY = 0;
    const frontTranslateZ = book.thickness/2;
    const backRotateY = 180;
    const backTranslateZ = book.thickness/2;
    const sideRotateY = -90;
    const sideTranslateZ = book.width/2;
	const startRotateY = 20;
	
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [bookRotateY, setBookRotateY] = useState(startRotateY);
	const [bookTranslateZ, setBookTranslateZ] = useState(-book.thickness/2);
	const scale = useBreakpointValue({base: 0.8, lg: 1.1, xl: 1.2});

	const rotateBook = () => {
		if (bookRotateY === startRotateY) { setBookRotateY(180); setBookTranslateZ(book.thickness/2); }
		else { setBookRotateY(startRotateY); setBookTranslateZ(-book.thickness/2); }
	}

	return (
		<Flex display={visibility === true ? `block` : `none`}>
			<Image
				objectFit={`cover`}
				w={`max(${book.width/1.5}px, min(${book.width/1.2}px, ${book.width/10}vw))`}
				h={`max(${book.height/1.5}px, min(${book.height/1.2}px, ${book.height/10}vw))`}
				src={book.cover.front}
				cursor={`pointer`}
				_hover={{
					transform: `scale(1.05)`,
					margin: `10px`,
					boxShadow: `5px 5px 20px 0px rgba(0,0,0,0.75)`
				}}
				boxShadow={`2.5px 2.5px 10px 0px rgba(0,0,0,0.75)`}
				transition={`all 0.25s ease`}
				onClick={onOpen}
			/>
			<Modal closeOnEsc={true} isCentered={false} size={`full`} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay bg={`blackAlpha.300`} backdropFilter={`blur(5px)`}/>
				<ModalContent bg={`none`} onClick={onClose} flexDirection={{base: `column`, lg: `row`}}>
					<ModalCloseButton />
					<Flex
						width={{base: `100%`, lg: `50%`}}
						height={{base: `${book.height}px`, lg: `100vh`}}
						alignItems={`center`}
						justifyContent={`center`}
					>
						<Flex __css={{perspective: `1000px`}}>
							<Flex
								w={`${book.width*scale}px`}
								h={`${book.height*scale}px`}
								position={`relative`}
								__css={{transformStyle: `preserve-3d`}}
								transform={`rotateY(${bookRotateY}deg) translateZ(${bookTranslateZ*scale}px)`}
								transition={`transform 0.75s ease-in-out`}
								onClick={(e) => {e.stopPropagation(); rotateBook()}}
								cursor={`pointer`}
							>
								{/* Front */}
								<Flex
									w={`${book.width*scale}px`}
									h={`${book.height*scale}px`}
									position={`absolute`}
									transform={`rotateY(${frontRotateY}deg) translateZ(${frontTranslateZ*scale}px)`}
									alignItems={`center`}
									justifyContent={`center`}
									backgroundColor={`gray.500`}
									bgImage={book.cover.front}
									bgSize={`cover`}
								/>
								{/* Back */}
								<Flex
									w={`${book.width*scale}px`}
									h={`${book.height*scale}px`}
									position={`absolute`}
									transform={`rotateY(${backRotateY}deg) translateZ(${backTranslateZ*scale}px)`}
									alignItems={`center`}
									justifyContent={`center`}
									backgroundColor={`gray.700`}
									bgImage={book.cover.back}
									bgSize={`cover`}
								/>
								{/* Side */}
								<Flex
									w={`${book.thickness*scale}px`}
									h={`${book.height*scale}px`}
									position={`absolute`}
									left={`${scale*(book.width - book.thickness)/2}px`}
									transform={`rotateY(${sideRotateY}deg) translateZ(${sideTranslateZ*scale}px)`}
									alignItems={`center`}
									justifyContent={`center`}
									backgroundColor={`gray.600`}
									bgImage={book.cover.side}
									bgSize={`cover`}
								/>
							</Flex>
						</Flex>
					</Flex>
					<Flex
						width={{base: `100%`, lg: `50%`}}
						minHeight={{base: `calc(100vh - ${book.height*scale}px)`, lg: `100%`}}
						background={`white`}
						boxShadow={`0px 0px 20px 0px rgba(0,0,0,0.75)`}
						onClick={(e) => e.stopPropagation()}
					>
						<Flex direction={`column`} w={`100%`} p={{base: `20px`, lg: `30px 20px 20px 20px`}}>
							<Text fontSize={`xl`} fontWeight={`semibold`}>{book.title}</Text>
							<Text mt={`5px`}>Year: {book.date}</Text>
							<Text mt={`5px`}>ISBN: {book.id}</Text>
							<Flex direction={`row`}>
								<Text pt={`5px`} m={`5px 10px 0px 0px`}>Tags:</Text>
								{book.filters && book.filters.map(filter => {
									return (<Text key={filter} fontWeight={`semibold`} p={`5px`} m={`5px 10px 0px 0px`} rounded={`lg`} backgroundColor={`orange.200`}>{filter}</Text>);
								})}
							</Flex>
							<Divider m={`20px 0px 20px 0px`}/>
							<Text fontSize={`lg`} fontWeight={`semibold`}>Book Description:</Text>
							{book.description && book.description.map((line, id) => {
								return (<Text key={id} mt={`15px`} fontSize={{base: `md`, lg: `lg`}}>{line}</Text>);
							})}
							<Flex w={`100%`} mt={`20px`} justifyContent={`center`}>
								<Button backgroundColor={`orange.200`} _hover={{backgroundColor: `orange.300`}} onClick={() => {window.open(book.link)}}>Read Here</Button>
							</Flex>
						</Flex>
					</Flex>
				</ModalContent>
			</Modal>
		</Flex>
	);
}

export default Book;