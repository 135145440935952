import { Box, Flex, ScaleFade, Text } from "@chakra-ui/react";
import { useRef } from "react";
import { useInViewport } from "react-in-viewport";

const TimeLineElement = ({children, right = false, lineSize = 2, lineColor = `#444`, iconSize = 30, iconColor = `#444`, date = ``, horizontalSpace = 50, verticalSpace = 300, arrowSize = 15}) => {

	const elementRef = useRef(null);
	const { inViewport } = useInViewport(elementRef, { rootMargin: `-100px` }, { disconnectOnLeave: false }, {});

	console.log(inViewport);

	return (
		<Flex>
			{!right ? (
				<Flex position={`relative`} left={`calc(50% + ${lineSize/2}px)`} w={`auto`} h={`${verticalSpace}px`} transform={`translateX(-100%)`} borderRight={`${lineSize}px solid ${lineColor}`} justifyContent={`flex-end`}>
					<Flex ref={elementRef} position={`absolute`} w={`${iconSize}px`} h={`${iconSize}px`} top={`50%`} right={`-${iconSize/2 + lineSize/2}px`} transform={`translateY(-50%)`} borderRadius={`full`} background={`${iconColor}`} />
					<ScaleFade initialScale={0.9} in={inViewport}>
						<Flex w={`100%`} h={`100%`} justifyContent={`flex-end`} alignItems={`center`}>
							<Text position={`absolute`} transform={`translateX(100%)`} right={`0px`} marginRight={`-${horizontalSpace+iconSize/2}`} color={`black`} fontSize={`2xl`} fontWeight={`semibold`}>{date}</Text>
							{children}
							{children && <Box w={`0px`} h={`0px`} borderTop={`${arrowSize}px solid transparent`} borderBottom={`${arrowSize}px solid transparent`} borderLeft={`${arrowSize}px solid white`} marginRight={`${horizontalSpace+iconSize/2}px`}/>}
						</Flex>
					</ScaleFade>
				</Flex>
			) :
			(
				<Flex position={`relative`} left={`calc(50% - ${lineSize/2}px)`} w={`auto`} h={`${verticalSpace}px`} borderLeft={`${lineSize}px solid ${lineColor}`}>
					<Flex ref={elementRef} position={`absolute`} w={`${iconSize}px`} h={`${iconSize}px`} top={`50%`} left={`-${iconSize/2 + lineSize/2}px`} transform={`translateY(-50%)`} borderRadius={`full`} background={`${iconColor}`} justifyContent={`center`} alignItems={`center`} />
					<ScaleFade initialScale={0.9} in={inViewport}>
						<Flex w={`100%`} h={`100%`} alignItems={`center`}>
							<Text position={`absolute`} transform={`translateX(-100%)`} left={`0px`} marginLeft={`-${horizontalSpace+iconSize/2}`} color={`black`} fontSize={`2xl`} fontWeight={`semibold`}>{date}</Text>
							{children && <Box w={`0px`} h={`0px`} borderTop={`${arrowSize}px solid transparent`} borderBottom={`${arrowSize}px solid transparent`} borderRight={`${arrowSize}px solid white`} marginLeft={`${horizontalSpace+iconSize/2}px`}/>}
							{children}
						</Flex>
					</ScaleFade>
				</Flex>
			)}
		</Flex>
	);
}

export default TimeLineElement;