import { Flex, Text } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

const About = () => {
	return (
		<Flex w={`100%`} h={`100vh`} justifyContent={`center`} alignItems={`center`}>
			<Text fontSize={`3xl`}>In the making...</Text>
		</Flex>
	);
}

export default About;