import { Button, Divider, Flex, Input, Link, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Books from "../assets/data/Books";
import Book from "../components/Book";

const Works = () => {
	const filters = [`Quran`, `Hadith`, `Tafsir`, `History`, `English`, `Arabic`, `French`];
	const filterStates = [];
	const bookStates = [];
	function createFilters() {
		for (let i = 0; i < filters.length; i++) filterStates.push(useState(false));
		for (let i = 0; i < Books.length; i++) bookStates.push(useState(true));
	}
	createFilters();

	const searchFilter = (event) => {
		let query = event.target.value;
		for (let i = 0; i < Books.length; i++) {
			if (Books[i].title.toLowerCase().indexOf(query.toLowerCase()) === -1) bookStates[i][1](false);
			else bookStates[i][1](true);
		}
	}

	useEffect(() => {
		for (let i = 0; i < Books.length; i++) {
			let matches = true;
			for (let j = 0; j < filters.length; j++) {
				if (filterStates[j][0] === false) continue;
				if (!Books[i].filters.includes(filters[j])) matches = false;
			}
			if (matches) bookStates[i][1](true);
			else bookStates[i][1](false);
		}
	}, [JSON.stringify(filterStates)]);

	return (
		<Flex w={`100%`} h={`100vh`} p={`25px`} direction={`column`}>
			<Flex w={`100%`} direction={`column`} justifyContent={`center`} alignItems={`center`} mb={`50px`}>
				<Flex w={`min(50%, 400px)`}>
					<Input placeholder={`Search`} size={`md`} onChange={searchFilter} />
				</Flex>
				<Flex w={`100%`} direction={`row`} flexWrap={`wrap`} gap={`25px`} justifyContent={`center`} alignItems={`center`} mt={`25px`}>
					{
						filters && filters.map((filter, index) => {
							return (
								<Button
									key={index}
									size={`sm`}
									opacity={filterStates[index][0] ? 1 : 0.5}
									border={filterStates[index][0] ? `1px solid` : `1px dashed`}
									fontWeight={filterStates[index][0] ? `medium` : `normal`}
									rounded={`3xl`}
									onClick={() => filterStates[index][1](!filterStates[index][0])}
								>
									{filter}
								</Button>
							);
						})
					}
					<Link onClick={() => filterStates.forEach(e => e[1](false))}>Reset</Link>
				</Flex>
			</Flex>
			<Flex w={`100%`} direction={`row`} gap={`50px`} flexWrap={`wrap`}>
				{Books && Books.map((book, index) => {
					return (
						<Book key={book.id} book={book} visibility={bookStates[index][0]}/>
					);
				})}
			</Flex>
		</Flex>
	);
}

export default Works;