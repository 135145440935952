import React from "react";
import ReactDOM from "react-dom/client";

import { ChakraProvider, extendTheme, ColorModeScript } from "@chakra-ui/react";
import App from "./App";

const theme = extendTheme({
	initialColorMode: `light`,
	useSystemColorMode: false,
});

const root = ReactDOM.createRoot(document.getElementById(`root`));
root.render(
	<ChakraProvider theme={theme}>
		<ColorModeScript initialColorMode={theme.config.initialColorMode} />
		<App />
	</ChakraProvider>
);