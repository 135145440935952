import { Button, Collapse, Flex, IconButton, Icon, Link, Popover, PopoverContent, PopoverTrigger, Stack, Text, useColorModeValue, useDisclosure, Image } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronRightIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";

import NavigationBarJSON from "../assets/data/NavigationBar.json";
import SiteLogo from "../assets/images/icons/logo512.png";

const NavigationBar = () => {
	return (
		<Flex
			position={`relative`}
			direction={`column`}
			w={`100%`}
		>
			<DesktopNav />
			<MobileNav />
		</Flex>
	);
}

const DesktopNav = () => {
	return (
		<Flex
			display={{base: `none`, md: `flex`}}
			w={`100%`}
			p={`4px 8px 4px 8px`}
			flexDirection={`row`}
			justifyContent={`space-between`}
		>
			<Stack direction={`row`} alignItems={`center`} spacing={`16px`}>
				<LogoLink padding={`0px`} margin={`0px`}/>
				{
					NavigationBarJSON.leftNavLinks.map((navLink) => {
						return (<DesktopNavLink navLink={navLink} key={navLink.label} />);
					})
				}
			</Stack>

			<Stack direction={`row`} alignItems={`center`} spacing={`16px`}>
				{
					NavigationBarJSON.rightNavLinks.map((navLink) => {
						return (<DesktopNavLink navLink={navLink} key={navLink.label} />);
					})
				}
				<Languages padding={`8px 16px 8px 16px`} margin={`0px`}/>
			</Stack>
		</Flex>
	);
}

const DesktopNavLink = ({navLink}) => {
	const navLinkBgHovorColor = useColorModeValue(`gray.100`, `gray.700`);

	return (
		<Flex>
		<Popover trigger={`hover`} placement={`bottom-start`} key={navLink.label}>
			<PopoverTrigger>
				<Link
					href={navLink.href ?? null}
					roundedTop={`md`}
					roundedBottom={navLink.href ? `md` : ``}
					p={`8px`}
					_hover={{
						textDecoration: `none`,
						bg: navLinkBgHovorColor
					}}
				>
					{navLink.label}
					{navLink.children && (<ChevronDownIcon ml={`4px`} />)}
				</Link>
			</PopoverTrigger>
			{
				navLink.children && (
					<PopoverContent
						p={`10px`}
						mt={`-10px`}
						border={`0`}
						roundedTop={`none`}
						roundedBottom={`xl`}
						boxShadow={`xl`}
					>
						<Stack direction={`column`}>
							{
								navLink.children.map((subNavLink) => {
									return (<DesktopSubNav subNavLink={subNavLink} key={subNavLink.label} />);
								})
							}
						</Stack>
					</PopoverContent>
				)
			}
		</Popover>
		</Flex>
	);
}

const DesktopSubNav = ({subNavLink}) => {
	const navSubLinkBgHoverColor = useColorModeValue(`orange.50`, `orange.900`);
    const navSubLinkTextHoverColor = useColorModeValue(`orange.500`, `orange.300`);
    const navSubLinkIconColor = useColorModeValue(`orange.500`, `orange.300`);

	return (
		<Link
            href={subNavLink.href}
            p={`10px`}
            rounded={`md`}
            role={`group`}
            _hover={{
                textDecoration: `none`,
                bg: navSubLinkBgHoverColor
            }}
        >
            <Flex
                direction={`row`}
                align={`center`}
            >
                <Flex direction={`column`}>
                    <Text
                        transition={`all .3 ease`}
                        _groupHover={{
                            color: navSubLinkTextHoverColor
                        }}
                        fontWeight={`500`}
                    >
                        {subNavLink.label}
                    </Text>
                    <Text fontSize={`sm`}>
                        {subNavLink.sublabel}
                    </Text>
                </Flex>
                <Flex
                    transition={`all .3s ease`}
                    transform={`translateX(-10px)`}
                    opacity={0}
                    _groupHover={{ opacity: `100%`, transform: `translateX(0)` }}
                    justify={`flex-end`}
                    align={`center`}
                    flex={1}
                >
                    <ChevronRightIcon color={navSubLinkIconColor} w={5} h={5} />
                </Flex>
            </Flex>
        </Link>
	);
}

const MobileNav = () => {
	const navButtonColor = useColorModeValue(`white`, `gray.800`);
	const { isOpen, onToggle } = useDisclosure();

	return (
		<Flex
			display={{base: `flex`, md: `none`}}
			w={`100%`}
			direction={`column`}
		>
			<Flex
				display={{base: `flex`, md: `none`}}
				w={`100%`}
				p={`4px 8px 4px 8px`}
				direction={`row`}
				justifyContent={`space-between`}
			>
				<IconButton
					onClick={onToggle}
					icon={
						isOpen ? <CloseIcon /> : <HamburgerIcon w={`24px`} h={`24px`} />
					}
					bg={navButtonColor}
					mr={`10%`}
				/>
				<LogoLink padding={`8px 16px 8px 16px`} margin={`0px`} />
				<Languages padding={`8px 16px 8px 16px`} margin={`0px`} />
			</Flex>
			<Collapse in={isOpen} animateOpacity>
				<Stack
					display={{md: `none`}}
					direction={`column`}
					mt={`2px`}
					p={`0px 20px 0px 20px`}
				>
					{
						NavigationBarJSON.leftNavLinks.map((navLink) => {
							return (<MobileNavItem navLink={navLink} key={navLink.label}/>);
						})
					}
					{
						NavigationBarJSON.rightNavLinks.map((navLink) => {
							return (<MobileNavItem navLink={navLink} key={navLink.label}/>);
						})
					}
				</Stack>
			</Collapse>
		</Flex>
	);
}

const MobileNavItem = ({navLink}) => {
	const { isOpen, onToggle } = useDisclosure();
    const navLinkColor = useColorModeValue(`gray.600`, `gray.200`);
    const navSubLinkBorderColor = useColorModeValue(`gray.600`,`gray.200`)

    return (
        <Stack
            spacing={`16px`}
            onClick={navLink.children && onToggle}
        >
            <Flex
                as={Link}
                href={navLink.href ?? `#`}
                p={`10px 0px 10px 0px`}
                justifyContent={`space-between`}
                align={`center`}
                _hover={{
                    textDecoration: `none`,
                }}
            >
                <Text fontWeight={`500`} color={navLinkColor}>
                    {navLink.label}
                </Text>
                {navLink.children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={`all .25s ease-in-out`}
                        transform={isOpen ? `rotate(180deg)` : ``}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <Stack
                    borderLeft={`1px`}
                    borderStyle={`solid`}
                    borderColor={navSubLinkBorderColor}
                    align={`start`}
                    p={`0px 0px 0px 10px`}
                >
                    {
                        navLink.children && navLink.children.map((subNavLink) => {
							return (
								<Link href={subNavLink.href} key={subNavLink.label}>
									{subNavLink.label}
								</Link>
							);
						})
                    }
                </Stack>
            </Collapse>
        </Stack>
    );
}

const LogoLink = ({padding, margin}) => {
	return (
		<Link
			href={`/`}
			rounded={`md`}
			p={padding}
			m={margin}
		>
			<Image src={SiteLogo} alt={`Azami Foundation Logo`} boxSize={`40px`}/>
		</Link>
	);
}

const Languages = ({padding, margin}) => {
	const languageBgHovorColor = useColorModeValue(`gray.100`, `gray.700`);
	const languageLabelBgHoverColor = useColorModeValue(`orange.50`, `orange.900`);
    const languageLabelTextHoverColor = useColorModeValue(`orange.500`, `orange.300`);

	return (
		<Flex>
		<Popover trigger={`hover`} placement={`bottom-start`} key={`language`}>
			<PopoverTrigger>
				<Flex
					roundedTop={`md`}
					p={padding}
					bg={`white`}
					_hover={{
						textDecoration: `none`,
						bg: languageBgHovorColor
					}}
					cursor={`pointer`}
					justifyContent={`center`}
					alignItems={`center`}
				>
					<Text>Language</Text>
					<ChevronDownIcon m={`4px 0px 0px 4px`} />
				</Flex>
			</PopoverTrigger>
			<PopoverContent
				border={`0`}
				width={`121px`}
				mt={`-10px`}
				roundedTop={`none`}
				roundedBottom={`xl`}
				boxShadow={`xl`}
			>
				<Stack direction={`column`} p={`10px`}>
					{
						NavigationBarJSON.languages.map((language) => {
							return (
								<Button
									key={language}
									bg={`white`}
									h={`max-content`}
									p={`2px 0px 2px 0px`}
									transition={`all .3 ease`}
									_hover={{
										bg: languageLabelBgHoverColor,
										color: languageLabelTextHoverColor
									}}
								>
									<Text fontWeight={`normal`}>{language}</Text>
								</Button>
							);
						})
					}
				</Stack>
			</PopoverContent>
		</Popover>
		</Flex>
	);
}

export default NavigationBar;