const Contributions = () => {
	return (
		<div style={{fontSize: `22px`}}>
			<p>This page sheds light on Dadu's hand in other works, be it revision of some book or somehing else.</p>
			<p style={{marginBottom: `10px`}}>Here's a compiled list of information relevant to this page:</p>
			<p>1. Computerization of the text of Ahadith</p>
			<p>2. موطا إمام مالك ... Written by Imam Malik. Dadu edited it and published it in 8 volumes</p>
			<p>3. صحيح ابن خزيمة ... Book compiled by Ibn Khuzayma. Dadu editted it and got it published in 4 volumes.</p>
			<p>4. العلل لعلي بن عبد الله المديني ... Another book edited by Dadu</p>
			<p>5. سنن ابن ماجة ... Edited and computerized by Dadu</p>
			<p>6. مغازي رسول الله صلى الله عليه وسلم لعروة بن زبير برواية أبي الأسود ... Hadrat Urwah bin Zubair wrote a biography of Prophet's (SAW) named صلى الله عليه وسلم, This book was published in Riyadh in 1981 after Dadu's critical review and analysis of the book.</p>
			<p>7. دراسات في الحديث النبوي وتاريخ تدوينه ... Dadu's doctoral thesis. In this book, Dadu has answered all the questions generally raised by the Orientalists to challenge the traditions of the Prophet (SAW).</p>
		</div>
	);
}

export default Contributions;