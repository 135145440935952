import HeroImage from "../images/album/King-Faisal-Award-Muhammad-Al-Azami-1980.jpg";
import InfoSectionImage1 from "../images/album/Darul-Uloom-Deoband.jpg";
import InfoSectionImage2 from "../images/album/Turkey-Conference-2.jpg";
import InfoSectionImage3 from "../images/album/Muhammad-Al-Azami.jpg";

export default {
	"homeHero": {
		"title": "About the Scholar",
		"description": "The late Muhammad Mustafa Al-Azmi is a renowned Hadith scholar who received the King Faisal International Award in 1980. Muhammad Mustafa's life was filled with many struggles, but his exemplary tenacity and thirst for knowledge made him a virtuous intellect.",
		"image": HeroImage,
		"imageTitle": "King Faisal Award",
		"imageInfo": "Sheikh Muhammad Mustafa Al-Azmi won the King Faisal Award... To Be Continued"
	},
	"homeInfo": [
		{
			"title": "Works of Muhammad Mustafa Al-Azmi",
			"text": [
				{"paragraph": "Muhammad Al-Azmi's work mostly spaned working on hadith litrature and studying old and new testaments, his literary work includes:"},
				{"list": [
					"Studies in Early Hadith Literature, His doctoral thesis at the University of Cambridge",
					"Hadith Methodology and Literature, a general introduction to the subject",
					"The History of the Qur'anic Text from Revelation to Compilation",
					"On Schacht's Origins of Muhammadan Jurisprudence",
					"Dirasat fi al-Hadith an-Nabawi",
					"Kuttab an-Nabi",
					"Manhaj an-Naqd 'ind al-Muhaddithin",
					"Al-Muhaddithun min al-Yamamah"
				]}
			],
			"linkText": "Explore Al-Azmi's Works",
			"linkHref": "/compositions",
			"sideImage": InfoSectionImage1,
			"sideImagePosition": "100% 0%",
			"sideImageGradient": "linear-gradient(to right, rgba(255, 20, 147, 0.3), rgba(255, 127, 80, 0.5))",
			"numberColor": "rgba(255, 127, 80, 0.5)"
		},
		{
			"title": "Journey of Muhammad Mustafa Al-Azmi",
			"text": [
				{"paragraph": "Muhammad Al-Azmi was born in Mau in the Indian province of Uttar Pradesh in 1930. He graduated from India’s well-known Islamic seminary Darul Uloom Deoband in 1950. He then travelled to Egypt for further studies in Arabic and Islamic studies and obtained a “Certificate of Alimiyat” with permission to teach from Al-Azhar University in 1955."},
				{"paragraph": "Muhammad Al-Azmi began his professional career in Qatar, where he taught Arabic to non-Arabs. After sometime he joined the National Public Library in Qatar as a librarian. An insatiable thirst for knowledge and a deep interest in the study of Hadith took him to the University of Cambridge, where he registered for the PHD programme under the supervision of the well-known Orientalist Arthur J. Arberry and R. B. Sergeant. He wrote his thesis on “Studies in early Hadith literature.”"}
			],
			"linkText": "Biography of Al-Azmi",
			"linkHref": "/biography",
			"sideImage": InfoSectionImage2,
			"sideImagePosition": "100% 0%",
			"sideImageGradient": "linear-gradient(to right, rgba(106, 90, 205, 0.3), rgba(0, 191, 255, 0.5))",
			"numberColor": "rgba(0, 191, 255, 0.5)"
		},
		{
			"title": "About Us",
			"text": [
				{"paragraph": "This website's purpose is to spread the knowledge gathered by Muhammad Mustafa Al-Azami through out his life and share his biography."}
			],
			"linkText": "More on About",
			"linkHref": "/contact-us",
			"sideImage": InfoSectionImage3,
			"sideImagePosition": "75% 0%",
			"sideImageGradient": "linear-gradient(to right, rgba(174, 243, 89, 0.3), rgba(61, 237, 151, 0.5))",
			"numberColor": "rgba(61, 237, 151, 0.5)"
		}
	]
};