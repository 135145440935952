import { LinkIcon } from "@chakra-ui/icons";
import { Flex, Image, Link, ListItem, Modal, ModalContent, ModalOverlay, Text, UnorderedList, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { ParallaxLayer as SpringParallaxLayer } from "@react-spring/parallax";


const HomeInfoSection = ({data, page}) => {
    
    const homeSectionPageColor = useColorModeValue(`#f2f2f2`, `#20232f`);
    const homeSectionLinkColor = useColorModeValue(`teal.500`, `teal.400`);
    
    return (
        <Flex>

        <SpringParallaxLayer offset={page} speed={0.2}>
            <Flex
                position={`relative`}
                w={`120%`}
                h={`100%`}
                alignItems={`center`}
                justifyContent={`center`}
                backgroundColor={homeSectionPageColor}
                clipPath={`polygon(20% 0, 70% 0, 50% 100%, 0% 100%)`}
                pointerEvents={`none`}
            />
        </SpringParallaxLayer>

        <SpringParallaxLayer offset={page} speed={0.6} style={{pointerEvents: `none`, zIndex: 1}}>
            <Flex
                position={`relative`}
                left={`60%`}
                w={`60%`}
                h={`100%`}
                alignItems={`center`}
                justifyContent={`center`}
                backgroundImage={`${data.sideImageGradient}, url(${data.sideImage})`}
                backgroundRepeat={`no-repeat`}
                backgroundSize={`cover`}
                backgroundPosition={data.sideImagePosition}
                clipPath={`polygon(40% 0%, 100% 0%, 60% 100%, 0% 100%)`}
                pointerEvents={`auto`}
            />
        </SpringParallaxLayer>

        <SpringParallaxLayer offset={page} speed={0.3} style={{pointerEvents: `none`}}>
            <Flex position={`absolute`} w={`100%`} h={`100%`} direction={`column`}>
                <Flex
                    position={`relative`}
                    p={`2vh 2vw 0px 0px`}
                    w={`82.5%`}
                    pointerEvents={`auto`}
                >
                    <Text 
                        w={`100%`} textAlign={`end`} 
                        fontSize={`max(30px, 4vw)`} color={data.numberColor}
                    >
                        0{page+1}
                    </Text>
                </Flex>

                <Flex
                    position={`relative`}
                    p={`0px 0px 0px 2vw`}
                    w={`80%`}
                    pointerEvents={`auto`}
                >
                    <Text
                        w={`100%`} fontSize={`max(30px, 4vw)`} fontWeight={`semibold`}
                    >
                        {data.title}
                    </Text>
                </Flex>

                <Flex
                    position={`relative`}
                    direction={`column`}
                    fontSize={`max(20px, 1.5vw)`}
                    p={`0px 0px 0px 2vw`}
                    w={`70%`}
                    lineHeight={`max(30px, 2.5vw)`}
                    pointerEvents={`auto`}
                >
                {
                    data.text && data.text.map((component) => {
                        if (component[`paragraph`]) {
                            return (
                                <Text key={component[`paragraph`]} m={`5px 25px 5px 0px`}>
                                    {component[`paragraph`]}
                                </Text>
                            );
                        }
                        else if (component[`list`]) {
                            return (
                                <UnorderedList key={component[`list`]}>
                                    {
                                        component["list"].map((text) => {
                                            return (
                                                <ListItem key={text} m={`5px 15px 5px 5px`}>
                                                    {text}
                                                </ListItem>
                                            );
                                        })
                                    }
                                </UnorderedList>
                            );
                        }
                        else return (<Text fontSize={`3xl`}>Please check JSON file's format!</Text>);
                    })
                }
                </Flex>

                <Flex pointerEvents={`auto`}>
                    <Link href={data.linkHref} color={homeSectionLinkColor} fontSize={`max(25px, 1.5vw)`} p={`0px 0px 0px 2vw`}>
                        <LinkIcon mr={`8px`}/>{data.linkText}
                    </Link>
                </Flex>
            </Flex>

        </SpringParallaxLayer>

        </Flex>
    );
}

export default HomeInfoSection;