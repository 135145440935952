import SiHMaL_Front from "../images/books/SiHMaL_Front.jpg";
import SiHMaL_Side from "../images/books/SiHMaL_Side.jpg";
import SiHMaL_Back from "../images/books/SiHMaL_Back.jpg";

export default [
	{
		"title": "Studies in Hadith Methodology and Literature - Reviewed Edition",
		"id": 9839154273,
		"width": 350,
		"height": 500,
		"thickness": 50,
		"cover": {
			"front": SiHMaL_Front,
			"side": SiHMaL_Side,
			"back": SiHMaL_Back
		},
		"date": "2002",
		"description": ["Although tremendous amount of literature on the methodology of Hadith is available in Arabic, this book has been written to fulfil the needs of modern college students whose medium of instruction is mainly English.",
			"The book has been divided into two parts. In Part One, the subject of Hadith Methodology has been dealt with, In Part Two, the author discusses the literature, introducing the Six Principal Bonks as well as six others which had great significance and represent a stage in the compilation of Hadith books",
			"Dr Muhammad Mustafa al-Azmi is Professor of Science of Hadith at University of Riyadh. His other works are On Schacht's Origins of Muhammadan Jurisprudence and Studies in Early Hadith Literature. The latter is a pioneer work of highest value in the field of Hadith studies in modern times, for which Dr Azmi was conferred the degree of Ph.D from the University of Cambridge."],
		"link": "https://muqith.files.wordpress.com/2016/03/studiesinhadithmethodologyandliteraturebyshaykhmuhammadmustafaal-azami_text.pdf",
		"filters": ["Hadith", "Tafsir", "English"]
	},
];