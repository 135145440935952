import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { useRef } from "react";
import { useInView } from "framer-motion";
import Bio from "../assets/data/Biography";
import ImageModal from "../components/ImageModal";

const Biography = () => {
	
	return (
		<Box position={`relative`} w={`100%`} h={`calc(100vh - 48px)`} background={`yellow.100`}>
			In the making...
		</Box>
	);
}

export default Biography;